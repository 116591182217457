import _ from 'lodash';
import React, { useEffect, useState } from "react";
import classes from './Menu.module.scss';
import $ from 'jquery';
import MenuItem from "../MenuItem/MenuItem";
import MenuCategoryButton from "../MenuCategoryButton/MenuCategoryButton";
import { useMenuStore } from "../../../store/menuStore";
import { mockDishes, mockCategories } from "../../../utils/mockData";
import MenuItemMobile from "../MenuItemMobile/MenuItemMobile";

const Menu = () => {
    const { categories, dishes, setCategories, setDishes } = useMenuStore();

    const [ selectedCategory, setSelectedCategory ] = useState({});
    const [ selectedDishMobile, setSelectedDishMobile ] = useState({});
    const [ popupDishMobile, setPopupDishMobile ] = useState(false);

    const openPopup = (newDish) => {
        setSelectedDishMobile(newDish);
        setPopupDishMobile(true);
        $("body").addClass("ovf-hidden ovf-padding");
    };

    const closePopup = () => {
        setPopupDishMobile(false);
        setSelectedDishMobile({});
        $("body").removeClass("ovf-hidden ovf-padding");
    };

    useEffect(() => {
        setCategories(mockCategories);
        setDishes(mockDishes);
    }, []);

    useEffect(() => {
        categories.length > 0 && setSelectedCategory(categories[0]);
    }, [ categories ]);

    return (
        <section className={classes.menuWrap} id="menu">
            <div className="container">
                <div className={classes.menu}>
                    <div className={classes.category}>
                        <h4 className={`${classes.categoryTitle} font-40`}>
                            Меню:
                        </h4>
                        <div className={classes.categoryList}>
                            {categories.map((category) =>
                                <MenuCategoryButton 
                                    key={category.id}
                                    category={category} 
                                    selectedCategory={selectedCategory} 
                                    setSelectedCategory={setSelectedCategory} 
                                />
                            )}
                        </div>
                    </div>
                    <div className={classes.menuList}>
                        {dishes
                            // .filter(dish => dish.category === selectedCategory.id)
                            .filter(dish => _.findIndex(dish.category, o => o === selectedCategory.id) !== -1)
                            .map((dish) =>
                                                // ======================================
                                                // ДОБАВИТЬ ТУТ ФИЛЬТРАФИЮ ЕБАНЫХ ВЕГАНОВ
                                                // ======================================
                                <MenuItem key={dish.id} dish={dish} selectedCategoryId={selectedCategory.id} />
                        )}
                    </div>
                    <div className={classes.mobMenuList}>
                        {dishes
                            .filter(dish => _.findIndex(dish.category, o => o === selectedCategory.id) !== -1)
                            .map((dish) =>
                                <MenuItemMobile 
                                    key={dish.id} 
                                    dish={dish} 
                                    openPopup={openPopup}
                                />
                        )}
                        <div className={`${classes.popupItemWrap} ${popupDishMobile && classes.active}`}>
                            <div 
                                className={classes.closeArea}
                                onClick={closePopup}    
                            ></div>
                            <MenuItem closePopup={closePopup} dish={selectedDishMobile} selectedCategoryId={selectedCategory.id} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Menu;
