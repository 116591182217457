import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import classes from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import i18n from "i18next";
import $ from "jquery";
import logo from "../../../assets/img/logo.svg";
import cartIcon from '../../../assets/img/header_cart.svg';
import { useCartStore } from "../../../store/cartStore";

const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const headerRef = useRef(null);
  const cartRef = useRef(null);

  const { cart, setVisible, totalCost } = useCartStore();

  // const [ cartOpened, setCartOpened ] = useState(false);

  const setLocale = (lang) => {
    i18n.changeLanguage(lang);
    navigate(`/${lang}/${_.last(pathname.split("/"))}`);
  };

  useEffect(() => {
    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale("ua");
    }
    // eslint-disable-next-line
  }, [pathname]);

  const listenScroll = () => {
    if (window.pageYOffset > 70) {
      $(headerRef.current).removeClass(classes.hideBg);
      $(headerRef.current).removeClass(classes.lightBg);
    } else {
      $(headerRef.current).addClass(classes.hideBg);
      $(headerRef.current).removeClass(classes.lightBg);
    }
  };

  useEffect(() => {
    $(headerRef.current).removeClass(classes.lightBg);
    window.addEventListener("scroll", (event) => {
      listenScroll();
    });
    listenScroll();
  }, [pathname]);
  useEffect(() => {
    if ($("body").hasClass("lock")) {
      $("body").removeClass("lock");
    }
  }, []);
  useEffect(() => {
    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale("ua");
      return;
    }
    setLocale(lang);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    $(cartRef.current).addClass(classes.cartChange);
    setTimeout(() => {
      $(cartRef.current).removeClass(classes.cartChange);
    }, 200);
  }, [ totalCost ]);

  return (
    <div
      ref={headerRef}
      className={`${classes.header} ${classes.hideBg} no-select`}
    >
      <div className={`container ${classes.container}`}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <Link to={`/${lang}/`}>
              <img src={logo} alt="" />
            </Link>
          </div>
          <div 
            ref={cartRef}
            className={classes.cart}
            onClick={() => setVisible(true)}
          >
            <img src={cartIcon} alt=''/>
            <p className={classes.cartNum}>
              {cart.length}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
