import React from "react";
import classes from './Button.module.scss';
import { Link, useParams } from "react-router-dom";

const Button = ({ children, route }) => {
    const { lang } = useParams();

    const scrollToMenu = () => {
        document.querySelector('#menu').scrollIntoView({
            behavior: 'smooth',
        });
    };

    if (route) {
        return (
            <Link
                to={`/${lang}${route}`}
                className={`${classes.button} ${classes.default}`}
            >
                {children}
            </Link>
        );
    }

    return (
        <div
            className={classes.button}
            onClick={scrollToMenu}
        >
            {children}
        </div>
    );
};

export default Button;
