import React from "react";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Checkout from "../../components/CheckoutPage/Checkout/Checkout";

const CheckoutPage = () => {
    return (
        <div className="ovf-hidden">
            <Header />
            <Checkout />
            <Footer />
        </div>
    );
};

export default CheckoutPage;
