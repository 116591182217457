import React from "react";
import classes from './Banner.module.scss';
import Button from "../../GlobalComponents/Button/Button";

const Banner = () => {
    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <h1 className={classes.title}>
                        <div className="font-32">
                            Онлайн Ресторан
                        </div>
                        <div className="font-48">
                            Української кухні
                        </div>
                    </h1>
                    <h3 className={`${classes.subtitle} font-24`}>
                        Українська кухня у вашому домі
                    </h3>
                    <p className={`${classes.text} font-16`}>
                        Спробуйте справжню українську кухню у вашому домі!
                        <br /> Ми пропонуємо широкий вибір традиційних українських страв, приготованих зі свіжих, натуральних продуктів.
                        <br /> Наша випічка також не залишить вас байдужими.
                    </p>
                    <h4 className={`${classes.subtext} font-20`}>
                        Замовляйте прямо зараз і насолоджуйтеся смаком України!
                    </h4>
                    <div className={classes.btn}>
                        <Button>
                            Замовити
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
