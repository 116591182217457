import React from "react";
import classes from './Input.module.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Label = ({ children }) => (
    <p className={classes.inputLabel}>
        {children}
    </p>
);

const Input = ({
    get,
    set,
    valid,
    label,
    type = "text",
    textarea = false,
    phone = false
}) => {

    if (phone) {
        return (
            <div className={classes.inputWrap}>
                <Label>{label}</Label>
                <PhoneInput
                    country={'ua'}
                    value={get}
                    onChange={phone => set(phone)}
                    containerClass={classes.phoneContainer}
                    inputClass={`${classes.phoneInput} ${!valid ? classes.error : ''}`}
                />
            </div>
        );
    };

    if (textarea) {
        return (
            <div className={classes.inputWrap}>
                <Label>{label}</Label>
                <textarea
                    className={classes.input}
                    placeholder=""
                    value={get}
                    onChange={(e) => set(e.target.value)}
                />
            </div>
        );
    };

    return (
        <div className={classes.inputWrap}>
            <Label>{label}</Label>
            <input
                className={`${classes.input} ${!valid ? classes.error : ''}`}
                type={type}
                placeholder=""
                value={get}
                onChange={(e) => set(e.target.value)}
            />
        </div>
    );
};

export default Input;
