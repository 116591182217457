import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/img/logo.svg";
import facebookIcon from '../../../assets/img/footer_facebook_icon.svg';
import instagramIcon from '../../../assets/img/footer_instagram_icon.svg';

const Footer = () => {
    return (
        <footer className={`${classes.footer} mt`}>
            <div className="container">
                <div className={classes.content}>
                    <img className={classes.logo} src={logo} alt=''/>
                    <div className={classes.social}>
                        <a href="#fb" target="_blank">
                            <img src={facebookIcon} alt=''/>
                        </a>
                        <a href="#insta" target="_blank">
                            <img src={instagramIcon} alt=''/>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
