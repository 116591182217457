import React from "react";
import classes from './MenuCategoryButton.module.scss';

const MenuCategoryButton = ({ category, selectedCategory, setSelectedCategory }) => {
    return (
        <div 
            className={`
                font-24
                no-select
                ${classes.categoryItem} 
                ${selectedCategory.id === category.id && classes.categoryItemSelected}
            `}
            onClick={() => setSelectedCategory(category)}
        >
            {category.name}
        </div>
    );
};

export default MenuCategoryButton;
