import HomePage from '../pages/HomePage/HomePage';
import CheckoutPage from '../pages/CheckoutPage/CheckoutPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/:lang/', element: HomePage },
    
    { path: '/checkout', element: CheckoutPage },
    { path: '/:lang/checkout', element: CheckoutPage },
];

export const headerRoutes = [
];
