import _ from 'lodash';
import React, { useEffect, useState } from "react";
import classes from './MenuItem.module.scss';
import cartIcon from '../../../assets/img/menu_item_cart.svg';
import { useCartStore } from "../../../store/cartStore";

const MenuItem = ({ dish, closePopup, selectedCategoryId }) => {
    const { cart, addDish, removeDish } = useCartStore();
    const [ fillingId, setFillingId ] = useState(0);
    const [ selectedFill, setSelectedFill ] = useState({});
    const [ currentDish, setCurrentDish ] = useState(dish);

    const updateSelectedFill = () => 
        setSelectedFill(dish.filling ? dish.filling[fillingId] : 0);

    const updateCurrentDish = () => {
        const secondPathUID = selectedFill === 0 ? 0 : selectedFill.name;

        setCurrentDish({
            ...dish,
            selectedFill,
            uid: `${dish.id}.${secondPathUID}`,
        });
    };
    
    useEffect(() => {
        updateCurrentDish();
    }, [ selectedFill, dish ]);

    useEffect(() => {
        updateSelectedFill();
    }, [ fillingId, dish ]);

    return (
        <div className={classes.menuItem}>
            <div className={classes.closeArea} onClick={closePopup}></div>
            <img 
                loading="lazy" 
                className={classes.image} 
                src={`/images/${dish.image}`}
                alt={dish.title}
            />
            <div className={classes.content}>
                <div className={classes.header}>
                    <h4 className={classes.title}>
                        {dish.title}
                    </h4>
                    <p className={`${classes.weight} font-16`}>
                        {dish.weight}г
                    </p>
                    {dish.pieces &&
                        <div className={classes.pieces}>
                            1 порція {dish.pieces} шт
                        </div>
                    }
                </div>
                {/* <p className={`${classes.description} font-16`}>
                    {dish.description}
                </p> */}
                {dish.filling &&
                    <div className={classes.filling}>
                        {dish.filling.map((fillItem, index) => {
                            
                            if (selectedCategoryId !== 2 || (selectedCategoryId === 2 && fillItem.vegan)) {
                                return <div 
                                    key={index} 
                                    className={`${classes.fillItem} ${index === fillingId ? classes.active : ''} no-select`}
                                    onClick={() => setFillingId(index)}                            
                                >
                                    <div className={classes.fillItem__checkbox}></div>
                                    <div className={classes.fillItem__content}>
                                        {fillItem.icon &&
                                            <img className={classes.fillItem__content_icon} src={`/icons/${fillItem.icon}`} alt='' />
                                        }
                                        <p className={classes.fillItem__content_text}>
                                            {fillItem.title}
                                        </p>
                                    </div>
                                </div>
                            }
                        }

                            // (selectedCategoryId !== 2 || (selectedCategoryId === 2 && fillItem.vegan)) &&
                            //     <div 
                            //         key={index} 
                            //         className={`${classes.fillItem} ${index === fillingId ? classes.active : ''} no-select`}
                            //         onClick={() => setFillingId(index)}                            
                            //     >
                            //         <div className={classes.fillItem__checkbox}></div>
                            //         <div className={classes.fillItem__content}>
                            //             {fillItem.icon &&
                            //                 <img className={classes.fillItem__content_icon} src={`/icons/${fillItem.icon}`} alt='' />
                            //             }
                            //             <p className={classes.fillItem__content_text}>
                            //                 {fillItem.title}
                            //             </p>
                            //         </div>
                            //     </div>
                        )}
                    </div>
                }
                <div className={classes.bottom}>
                    <p className={classes.cost}>
                        {dish.cost}€
                    </p>
                    <div className={classes.cart}>
                        <div className={`${classes.cartActions} no-select`}>
                            <div onClick={() => removeDish(currentDish)}>-</div>
                            <div>
                                {_.find(cart, { uid: currentDish.uid })?.count ?? 0}
                            </div>
                            <div onClick={() => addDish(currentDish)}>+</div>
                        </div>
                        <div className={classes.cartIcon}>
                            <img src={cartIcon} alt=''/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuItem;
