import $ from 'jquery';
import React, { useEffect } from "react";
import classes from './Cart.module.scss';
import { Link, useParams, useLocation } from "react-router-dom";
import { useCartStore } from "../../../store/cartStore";
import CartItem from "./CartItem";

const Cart = ({ visible, setVisible }) => {
    const { lang } = useParams();
    const { cart, totalCost } = useCartStore();
    const { pathname } = useLocation();

    useEffect(() => {
        visible
            ? $('body').addClass("ovf-hidden ovf-padding")
            : $('body').removeClass("ovf-hidden ovf-padding");
    }, [visible]);

    useEffect(() => {
        setVisible(false);
        // eslint-disable-next-line
    }, [pathname]);

    return (
        <div className={`${classes.cartWrap} ${visible ? classes.visible : ''}`}>
            <div onClick={() => setVisible(false)} className={classes.closeArea}></div>
            <div className={`${classes.container} container`}>
                <div className={classes.cart}>
                    <div className={classes.top}>
                        Кошик
                        <div
                            onClick={() => setVisible(false)}
                            className={classes.cartClose}
                        ></div>
                    </div>
                    <div className={classes.cartList}>
                        {totalCost < 32 &&
                            <div className={classes.minCostAlert}>
                                Мінімальна сума замовлення 32€.<br />
                                Поточна сума {totalCost}€.
                            </div>
                        }
                        {cart.map((dish, index) =>
                            <CartItem key={index} dish={dish} />
                        )}
                    </div>
                    <div className={classes.cartBottom}>
                        <div className={classes.totalCost}>
                            <p className={classes.totalCostText}>
                                Сума замовлення
                            </p>
                            <p className={classes.totalCostSum}>
                                {totalCost}€
                            </p>
                        </div>
                        {totalCost >= 32 &&
                            <Link
                                to={`/${lang}/checkout`}
                                className={classes.confirmButton}
                            >
                                Оформити замовлення
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cart;
