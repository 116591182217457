import React from "react";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Menu from "../../components/HomePage/Menu/Menu";
import Banner from "../../components/HomePage/Banner/Banner";

const HomePage = () => {
    return (
        <div className="ovf-hidden">
            <Header />
            <Banner />
            <Menu />
            <Footer />
        </div>
    );
};

export default HomePage;
