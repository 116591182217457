export const mockCategories = [
    {
        id: 0,
        name: 'Страви',
    },
    {
        id: 1,
        name: 'Випічка',
    },
    {
        id: 2,
        name: 'Вегетаріанські Страви',
    },
];

const cow = {
    icon: 'cow.svg',
    name: 'cow',
    title: 'яловичина',
};

const chicken = {
    icon: 'chicken.svg',
    name: 'chicken',
    title: 'курятина',
};

const pig = {
    icon: 'pig.svg',
    name: 'pig',
    title: 'свинина',
};

const potato = {
    icon: 'potato.svg',
    name: 'potato',
    title: 'картопля',
    vegan: true,
};

const cabbage = {
    icon: 'cabbage.svg',
    name: 'cabbage',
    title: 'капуста',
    vegan: true,
};

const strawberry = {
    icon: 'strawberry.svg',
    name: 'strawberry',
    title: 'полуниця',
    vegan: true,
};

const cherry = {
    icon: 'cherry.svg',
    name: 'cherry',
    title: 'вишня',
    vegan: true,
};

const acai = {
    icon: 'acai.svg',
    name: 'acai',
    title: 'мікс ягід',
    vegan: true,
};

const fish = {
    icon: 'fish.svg',
    name: 'fish',
    title: 'риба (мінтай)',
    vegan: true,
};

const apricots_raisins = {
    name: 'apricots_raisins',
    title: 'з курагою та ізюмом',
    vegan: true,
};

const standard = {
    name: 'standard',
    title: 'звичайні стандартні',
    vegan: true,
};

const cottage_cheese_raisins = {
    icon: 'raisins.svg',
    name: 'raisins',
    title: 'з творогом і ізюмом',
    vegan: true,
};

const cherries_berry_jam = {
    icon: 'cherry.svg',
    name: 'cherry',
    title: 'з вишнями або ягідним джемом',
    vegan: true,
};

const chocolate = {
    icon: 'cacao.svg',
    name: 'cacao',
    title: 'з шоколадом',
    vegan: true,
};

const cheese_herbs = {
    icon: 'cheese.svg',
    name: 'cheese',
    title: 'з сиром та зеленню',
    vegan: true,
};

const puree = {
    name: 'puree',
    title: 'пюре',
    vegan: true,
};

const baked_pieces = {
    name: 'baked_pieces',
    title: 'запечена шматочками',
    vegan: true,
};

const blackcurrant_jam = {
    icon: 'currant.svg',
    name: 'currant',
    title: 'з джемом із чорної смородини',
    vegan: true,
};

const apricot_jam = {
    icon: 'apricot.svg',
    name: 'apricot',
    title: 'з абрикосовим джемом',
    vegan: true,
};

export const mockDishes = [
    {
        id: 0,
        category: [0],
        image: 'dish_01.jpg',
        title: 'Борщ',
        description: 'Традиційна українська страва, яка готується з буряка, капусти, картоплі, моркви, цибулі та інших овочів. Борщ може бути як м\'ясний, так і вегетаріанський.',
        cost: '8',
        weight: '250',
    },
    {
        id: 1,
        category: [0, 2],
        image: 'dish_02.jpg',
        title: 'Вареники',
        description: 'Традиційна українська страва, яка готується з тіста з різноманітними начинками. Вареники подаються з картоплею, капустою, грибами, м\'ясом та вишнею.',
        cost: '15',
        weight: '300',
        pieces: 6,
        filling: [
            cow,
            chicken,
            pig,
            potato,
            cabbage,
        ],
    },
    {
        id: 3,
        category: [0, 2],
        image: 'dish_03.jpg',
        title: 'Солодкі вареники',
        description: '',
        cost: '15',
        weight: '300',
        pieces: 6,
        filling: [
            strawberry,
            cherry,
            acai,
        ],
    },
    {
        id: 4,
        category: [0, 2],
        image: 'dish_04.jpg',
        title: 'Зрази картопляні',
        description: '',
        cost: '15',
        weight: '200',
        pieces: 2,
        filling: [
            cow,
            chicken,
            pig,
            cabbage,
        ],
    },
    {
        id: 5,
        category: [0, 2],
        image: 'dish_05.jpg',
        title: 'Котлети',
        description: '',
        cost: '11',
        weight: '120',
        pieces: 2,
        filling: [
            cow,
            chicken,
            pig,
            fish,
        ],
    },
    {
        id: 6,
        category: [0],
        image: 'dish_06.jpg',
        title: 'Пельмені варені',
        description: '',
        cost: '15',
        weight: '300',
        pieces: 10,
        filling: [
            cow,
            chicken,
            pig,
        ],
    },
    {
        id: 7,
        category: [0],
        image: 'dish_07.jpg',
        title: 'Пельмені заморожені',
        description: '',
        cost: '15',
        weight: '300',
        pieces: 10,
        filling: [
            cow,
            chicken,
            pig,
        ],
    },
    {
        id: 8,
        category: [0, 2],
        image: 'dish_08.jpg',
        title: 'Сирники',
        description: '',
        cost: '10',
        weight: '200',
        pieces: 3,
        filling: [
            apricots_raisins,
            standard,
        ],
    },
    {
        id: 9,
        category: [0, 2],
        image: 'dish_09.jpg',
        title: 'Млинці',
        description: '',
        cost: '15',
        weight: '210',
        pieces: 3,
        filling: [
            cow,
            chicken,
            pig,
            cabbage,
        ],
    },
    {
        id: 10,
        category: [0, 2],
        image: 'dish_10.jpg',
        title: 'Солодкі млинці',
        description: '',
        cost: '15',
        weight: '210',
        pieces: 3,
        filling: [
            cottage_cheese_raisins,
            cherries_berry_jam,
            chocolate,
        ],
    },
    {
        id: 11,
        category: [0],
        image: 'dish_11.jpg',
        title: 'Перці фаршировані рисом і мʼясом',
        description: '',
        cost: '5',
        weight: '150',
        pieces: 1,
        filling: [
            cow,
            chicken,
            pig,
        ],
    },
    {
        id: 12,
        category: [0],
        image: 'dish_12.jpg',
        title: 'Голубці рис з мʼясом ',
        description: '',
        cost: '15',
        weight: '150',
        pieces: 3,
        filling: [
            cow,
            chicken,
            pig,
        ],
    },
    {
        id: 13,
        category: [0, 2],
        image: 'dish_13.jpg',
        title: 'Чебуреки',
        description: '',
        cost: '8',
        weight: '120',
        pieces: 1,
        filling: [
            cow,
            chicken,
            pig,
            cabbage,
            cheese_herbs,
        ],
    },
    {
        id: 14,
        category: [0],
        image: 'dish_14.jpg',
        title: 'Деруни з мʼясом',
        description: '',
        cost: '13',
        weight: '350',
        pieces: 6,
        filling: [
            cow,
            chicken,
            pig,
        ],
    },
    {
        id: 15,
        category: [0, 2],
        image: 'dish_15.jpg',
        title: 'Картопля',
        description: '',
        cost: '6',
        weight: '150',
        filling: [
            puree,
            baked_pieces,
        ],
    },
    {
        id: 16,
        category: [0, 2],
        image: 'dish_16.jpg',
        title: 'Рис відварний',
        description: '',
        cost: '6',
        weight: '150',
    },
    {
        id: 17,
        category: [0, 2],
        image: 'dish_17.jpg',
        title: 'Гречка відварна',
        description: '',
        cost: '6',
        weight: '150',
    },
    {
        id: 18,
        category: [1],
        image: 'dish_18.jpg',
        title: 'Пиріжки з мʼясом',
        description: '',
        cost: '4',
        weight: '150',
        pieces: 1,
        filling: [
            cow,
            chicken,
            pig,
        ],
    },
    {
        id: 19,
        category: [1, 2],
        image: 'dish_19.jpg',
        title: 'Пиріжки з капустою та морквою',
        description: '',
        cost: '4',
        weight: '150',
        pieces: 1,
    },
    {
        id: 20,
        category: [1, 2],
        image: 'dish_20.jpg',
        title: 'Хліб плетений (білий)',
        description: '',
        cost: '5',
        weight: '300',
    },
    {
        id: 21,
        category: [1, 2],
        image: 'dish_21.jpg',
        title: 'Хліб житньо-пшеничний',
        description: '',
        cost: '5',
        weight: '400',
    },
    {
        id: 22,
        category: [1],
        image: 'dish_22.jpg',
        title: 'Булочка с міксом ягід',
        description: '',
        cost: '3',
        weight: '120',
    },
    {
        id: 23,
        category: [1],
        image: 'dish_23.jpg',
        title: 'Лимонний тарт',
        description: 'пісочне тісто, лимонний квот і меренга',
        cost: '3',
        weight: '80',
    },
    {
        id: 24,
        category: [1],
        image: 'dish_24.jpg',
        title: 'Булочка-коса з яблуками та корицею',
        description: '',
        cost: '3',
        weight: '80',
    },
    {
        id: 25,
        category: [1],
        image: 'dish_25.jpg',
        title: 'Тістечко «пташине молоко»',
        description: '',
        cost: '3.5',
        weight: '60',
    },
    {
        id: 26,
        category: [1],
        image: 'dish_26.jpg',
        title: 'Сінабони',
        description: 'з сиром Філадельфія та корицею, всередині, та солодкою помадкою зверху',
        cost: '3',
        weight: '120',
    },
    {
        id: 27,
        category: [1],
        image: 'dish_27.jpg',
        title: 'Конвертик з пісочного тіста  з курагою',
        description: '',
        cost: '3',
        weight: '100',
        pieces: 2,
    },
    {
        id: 28,
        category: [1],
        image: 'dish_28.jpg',
        title: 'Тертий пиріг «з дитинства»',
        description: '',
        cost: '3',
        weight: '120',
        filling: [
            blackcurrant_jam,
            apricot_jam,
        ],
    },
    {
        id: 29,
        category: [1],
        image: 'dish_29.jpg',
        title: 'Пасха (Куліч)',
        description: '',
        cost: '4',
        weight: '170',
    },
    {
        id: 30,
        category: [1],
        image: 'dish_29.jpg',
        title: 'Пасха (Куліч)',
        description: '',
        cost: '6',
        weight: '350',
    },
    {
        id: 31,
        category: [1],
        image: 'dish_30.jpg',
        title: 'Сочник (з сиром)',
        description: '',
        cost: '3',
        weight: '120',
    },
];