import React from "react";
import classes from './SuccessOrder.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";

const SuccessOrder = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.successOrder}>
                <p className={`${classes.text} font-24`}>
                    <Trans>
                        Дякуємо за замовлення!<br />Ваше замовлення в обробці.
                    </Trans>
                </p>
                <div className={classes.btn}>
                    <Button route="/">
                        Повернутись
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SuccessOrder;
