import _ from 'lodash';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware'

const updateTotalCost = (cart) => {
    let cost = 0;
    _.forEach(cart, dish => cost += (dish.cost * dish.count));
    return cost;
};

export const useCartStore = create(
    persist((set, get) => ({
        
        cart: [],
        totalCost: 0,
        visible: false,

        addDish: (dish) => {
            let newCart = get().cart;
            const dishIndex = _.findIndex(newCart, { uid: dish.uid });

            if (dishIndex === -1) {
                // add new dish
                newCart = [ ...newCart, { ...dish, count: 1 } ];
            } else {
                // increase count of an existing dish
                newCart[dishIndex].count += 1;
            }
            
            set(() => ({ cart: newCart }));
            set(() => ({ totalCost: updateTotalCost(newCart) }));
        },
        
        removeDish: (dish, total = false) => {
            let newCart = get().cart;
            const dishIndex = _.findIndex(newCart, { uid: dish.uid });

            if (dishIndex === -1) {
                // dish doesn't exist in cart
                return;
            }
            if (newCart[dishIndex].count <= 1 || total) {
                // remove dish
                _.remove(newCart, { uid: dish.uid })
            } else {
                // decrease count of an existing dish
                newCart[dishIndex].count -= 1;
            }

            set(() => ({ cart: newCart }));
            set(() => ({ totalCost: updateTotalCost(newCart) }));
        },

        clearCart: () => set({ cart: [], totalCost: 0 }),
        
        setVisible: (visible) => set({ visible }),
    }),
    {
        name: 'cart-storage',
        storage: createJSONStorage(() => sessionStorage),
    },
));
