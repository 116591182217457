import React from "react";
import classes from './MenuItemMobile.module.scss';

const MenuItemMobile = ({ dish, openPopup }) => {
    return (
        <div 
            className={classes.menuItem}
            onClick={() => openPopup(dish)}
        >
            <img 
                loading="lazy" 
                className={classes.image} 
                src={`/images/${dish.image}`}
                alt={dish.title}
            />
            <div className={classes.content}>
                <div className={classes.header}>
                    <h4 className={classes.title}>
                        {dish.title}
                    </h4>
                    <p className={classes.weight}>
                        {dish.weight}г
                    </p>
                </div>
                <p className={classes.description}>
                    {dish.description}
                </p>
                <p className={classes.cost}>
                    {dish.cost}€
                </p>
            </div>
        </div>
    );
};

export default MenuItemMobile;
