import React from "react";
import classes from './Cart.module.scss';
import { useCartStore } from "../../../store/cartStore";
import crossIcon from '../../../assets/img/cart_cross.svg';

const CartItem = ({ dish }) => {
    const { addDish, removeDish } = useCartStore();
    return (
        <div  className={classes.dish}>
            <img 
                loading="lazy" 
                className={classes.image} 
                src={`/images/${dish.image}`}
                alt={dish.title}
            />
            <div className={classes.content}>
                <div className={classes.top}>
                    <p className={classes.title}>
                        {dish.title}
                    </p>
                    <p className={classes.weight}>
                        {dish.weight}г
                    </p>
                    <div 
                        onClick={() => removeDish(dish, true)}
                        className={classes.removeDish}
                    >
                        <img src={crossIcon} alt=''/>
                    </div>
                </div>
                {dish.selectedFill !== 0 &&
                    <div className={classes.filling}>
                        {dish.selectedFill.icon &&
                            <img className={classes.fillItem__content_icon} src={`/icons/${dish.selectedFill.icon}`} alt='' />
                        }
                        <p className={classes.fillItem__content_text}>
                            {dish.selectedFill.title}
                        </p>
                    </div>
                }
                <div className={classes.bottom}>
                    <div className={`${classes.actions} no-select`}>
                        <div onClick={() => removeDish(dish)}>-</div>
                        <div>{dish.count ?? 0}</div>
                        <div onClick={() => addDish(dish)}>+</div>
                    </div>
                    <div className={classes.cost}>
                        {dish.cost * dish.count}€
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartItem;
