import _ from 'lodash';
import React, { useEffect, useState } from "react";
import classes from './Checkout.module.scss';
import inputClasses from '../../GlobalComponents/Input/Input.module.scss';
import { useCartStore } from "../../../store/cartStore";
import axios from 'axios';
import Input from '../../GlobalComponents/Input/Input';
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { validatePhone, validateString, validateEmail } from '../../../utils/validation';
import SuccessOrder from '../SuccessOrder/SuccessOrder';
import CartEmpty from '../CartEmpty/CartEmpty';

const paymentTypes = [
    {
        id: 0,
        title: 'Готівкою (тільки для самовивозу)',
    },
    {
        id: 1,
        title: 'Карткою (Visa, Mastercard)',
    },
];

const deliveryTypes = [
    {
        value: 0,
        label: 'Безкоштовна доставка',
    },
    {
        value: 1,
        label: 'Самовивіз',
    },
];

const Checkout = () => {
    const { cart, totalCost, clearCart } = useCartStore();

    const [success, setSuccess] = useState(false);

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [deliveryType, setDeliveryType] = useState(deliveryTypes[0]);
    const [address, setAddress] = useState('');
    const [description, setDescription] = useState('');
    const [paymentType, setPaymentType] = useState(paymentTypes[0]);

    const [nameValid, setNameValid] = useState(true);
    const [phoneValid, setPhoneValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [addressValid, setAddressValid] = useState(true);

    const setDataDefaultState = () => {
        setName('');
        setPhone('');
        setEmail('');
        setAddress('');
        setDescription('');
        setNameValid(true);
        setPhoneValid(true);
        setEmailValid(true);
        setAddressValid(true);
    };

    const validateInputData = () => {
        let valid = true;
        const validArr = [];

        validArr.push(validateString(name, setNameValid));
        validArr.push(validatePhone(phone, setPhoneValid));
        validArr.push(validateEmail(email, setEmailValid));
        if (deliveryType.value === 0) {
            validArr.push(validateString(address, setAddressValid));
        }

        _.map(validArr, (item) => !item && (valid = false));
        console.log(validArr);
        return valid;
    };

    const getCartItem = (dish) => {
        const fill = dish.selectedFill
            ? ` (${dish.selectedFill.title}) `
            : '';
        return `${dish.title}${fill}\nx${dish.count} * ${dish.cost}€\n${dish.count * dish.cost} €`;
    };

    const getCartString = () => {
        const cartString = _.join(
            _.map(cart, (dish) => getCartItem(dish)),
            '\n———\n'
        );
        return `<pre language="ORDER">${cartString}\n\nTotal: ${totalCost} €</pre>`;
    };

    const sendMessage = async () => {
        const cartString = getCartString();

        const deliveryString = `Delivery type: ${deliveryType.label}${deliveryType.value === 0 ? `\nAddress: ${address}\nComment: ${description}` : ''}`;

        const message = `Name: ${name}\nPhone: ${phone}\nEmail: ${email}\n\n${deliveryString}\n\nPayment type: ${paymentType.title}\n\n${cartString}`;

        setDataDefaultState();

        try {
            const { data } = await axios.post('https://bakery.devindex.net/form.php', JSON.stringify(message))
            if (data === 200) {
                setSuccess(true);
                clearCart();
            } else {
                throw ({ message: 'Something went wrong. Try again.' })
            }
        } catch (error) {
            alert(`${error.message}`)
        }
    };

    const submit = async () => {
        if (validateInputData()) {
            sendMessage();
        }
        return;
    };

    if (success) {
        return <SuccessOrder />
    }

    if (cart.length <= 0 ) {
        return <CartEmpty />
    }

    return (
        <section className={classes.checkoutWrap}>
            <div className="container">
                <div className={classes.checkout}>
                    <h2 className={`${classes.title} font-40`}>
                        Оформлення замовлення
                    </h2>
                    <p className={`${classes.text} font-16`}>
                        Ми будемо раді виконати ваше замовлення!
                    </p>
                    <div className={classes.content}>
                        <div className={classes.col}>
                            <div className={`${classes.step} ${classes.stepActive}`}>
                                <div className={classes.stepTitle}>
                                    <div className={classes.stepTitleNum}>
                                        1
                                    </div>
                                    <p className={`${classes.stepTitleText} font-24`}>
                                        Дані
                                    </p>
                                </div>
                                <div className={classes.stepContent}>
                                    <div className={classes.inputs}>
                                        <Input
                                            label="Ім’я"
                                            get={name}
                                            set={setName}
                                            valid={nameValid}
                                        />
                                        <Input
                                            label="Телефон"
                                            phone
                                            get={phone}
                                            set={setPhone}
                                            valid={phoneValid}
                                        />
                                        <Input
                                            label="Email"
                                            type="email"
                                            get={email}
                                            set={setEmail}
                                            valid={emailValid}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.step} ${classes.stepActive}`}>
                                <div className={classes.stepTitle}>
                                    <div className={classes.stepTitleNum}>
                                        2
                                    </div>
                                    <p className={`${classes.stepTitleText} font-24`}>
                                        Доставка
                                    </p>
                                </div>
                                <div className={classes.stepContent}>
                                    <div className={classes.inputs}>
                                        <div className={inputClasses.inputWrap}>
                                            <p className={inputClasses.inputLabel}>
                                                Спосіб доставки
                                            </p>
                                            <Dropdown
                                                options={deliveryTypes}
                                                onChange={(e) => setDeliveryType(e)}
                                                value={deliveryType}
                                                placeholder="EN"
                                                className={inputClasses.dd}
                                                controlClassName={inputClasses.ddControl}
                                                placeholderClassName={inputClasses.ddPlaceholder}
                                            />
                                        </div>
                                        {deliveryType.value === 0 &&
                                            <>
                                                <Input
                                                    label="Адреса"
                                                    get={address}
                                                    set={setAddress}
                                                    valid={addressValid}
                                                />
                                                <Input
                                                    label="Коментарі"
                                                    textarea
                                                    get={description}
                                                    set={setDescription}
                                                />
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.step} ${classes.stepActive}`}>
                                <div className={classes.stepTitle}>
                                    <div className={classes.stepTitleNum}>
                                        3
                                    </div>
                                    <p className={`${classes.stepTitleText} font-24`}>
                                        Оплата
                                    </p>
                                </div>
                                <div className={classes.stepContent}>
                                    <div className={classes.optionsWrap}>
                                        <p className={classes.optionsTitle}>
                                            Оберіть спосіб оплати
                                        </p>
                                        <div className={classes.optionsList}>
                                            {paymentTypes.map((type) =>
                                                <div
                                                    key={type.id}
                                                    className={classes.option}
                                                    onClick={() => setPaymentType(type)}
                                                >
                                                    <div className={`${classes.optionRatio} ${type.id === paymentType.id && classes.optionRatioActive}`}></div>
                                                    <p className={`${classes.optionTitle} no-select`}>
                                                        {type.title}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.button}
                                        onClick={submit}
                                    >
                                        Далі
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.col}>
                            <div className={classes.cart}>
                                <div className={classes.cartHeader}>
                                    <p className={`${classes.cartHeaderTitle} font-24`}>
                                        Ваше замовлення
                                    </p>
                                </div>
                                <div className={classes.cartContent}>
                                    {cart.map((dish, index) =>
                                        <div key={index} className={classes.dish}>
                                            <img
                                                loading="lazy"
                                                className={classes.dishImage}
                                                src={`/images/${dish.image}`}
                                                alt={dish.title}
                                            />
                                            <div className={classes.dishContent}>
                                                <div className={classes.dishHeader}>
                                                    <p className={classes.dishTitle}>
                                                        {dish.title}
                                                    </p>
                                                    {dish.selectedFill !== 0 &&
                                                        <div className={classes.filling}>
                                                            {dish.selectedFill.icon &&
                                                                <img className={classes.fillItem__content_icon} src={`/icons/${dish.selectedFill.icon}`} alt='' />
                                                            }
                                                            <p className={classes.fillItem__content_text}>
                                                                {dish.selectedFill.title}
                                                            </p>
                                                        </div>
                                                    }
                                                </div>
                                                <p className={classes.dishCount}>
                                                    {dish.count}
                                                </p>
                                                <p className={classes.dishWeight}>
                                                    {dish.weight}г
                                                </p>
                                            </div>
                                            <div className={`${classes.dishCost} font-24`}>
                                                {dish.cost * dish.count}€
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className={classes.cartFooter}>
                                    <p className={classes.cartFooterText}>
                                        Сума замовлення
                                    </p>
                                    <p className={`${classes.cartFooterSum} font-24`}>
                                        {totalCost}€
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Checkout;
