import React from "react";
import classes from './CartEmpty.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";

const CartEmpty = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.cartEmpty}>
                <p className={`${classes.text} font-24`}>
                    Мінімальна сума замовлення 32€.
                </p>
                <div className={classes.btn}>
                    <Button route="/">
                        Повернутись
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CartEmpty;
